<template>
   <conponent :is="renderComponent" />
</template>

<script>
    import cookie from 'vue-cookies'

    export default {
        name: 'Base',
        computed: {
            renderComponent: function(){
                return (cookie.isKey('msuid')) ? () => import('@/views/Home') : () => import('@/views/Login')
            }
        }
    }
</script>